import classNames from 'classnames'
import { useField } from 'formik'
import IMask from 'imask'
import * as React from 'react'
import { Checkmark } from 'src/assets/icons/customIcons/Checkmark'
import { Close2Icon } from 'src/assets/icons/customIcons/Close2'
import { useTranslatable } from 'src/hooks/locale/utils'
import { useFormData } from './Form'

interface Props {
  readonly isHorizontal?: boolean
  readonly className?: string
  readonly searchField?: boolean
  readonly placeholder?: string
  readonly name: string
  readonly required?: boolean
  readonly disabled?: boolean
  readonly type: string
  readonly label?: string
  readonly value?: string | null | undefined
  readonly rightAlign?: boolean
  readonly fixedLabelWidth?: boolean
  readonly instructions?: React.ReactElement
  readonly sendOrDelete?: boolean
}

interface IMaskInputProps {
  readonly mask?: string
  readonly onChangeMask?: (valueToMask: string | null | undefined) => void
}

export default function TextInput({
  searchField,
  fixedLabelWidth,
  instructions,
  isHorizontal,
  rightAlign,
  sendOrDelete,
  mask,
  required,
  ...props
}: Props & React.InputHTMLAttributes<HTMLInputElement> & IMaskInputProps): React.ReactElement {
  const inputRef = React.useRef<HTMLInputElement>(null)

  const t = useTranslatable()
  const [field, meta, helpers] = useField({
    name: props.name,
    validate(value: string) {
      if (required === true && value === '') {
        return props.label !== undefined ? `${props.label} ${t('common:is_required')}` : t('common:field_is_required')
      }
    },
  })

  React.useEffect(() => {
    if (inputRef.current != null && mask !== undefined) {
      const maskOptions = { mask }
      const maskInstance = IMask(inputRef.current, maskOptions)

      return () => {
        maskInstance.destroy()
      }
    }
  }, [mask])

  const onChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
      if (props.type === 'number') {
        // allow only numbers,floats and negative sign
        if (value.match(/^-?[0-9]*\.?[0-9]*$/)) {
          helpers.setValue(value)
        }
      } else {
        helpers.setValue(value)
      }
    },
    [helpers, props.type]
  )
  const handleKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (props.type === 'number' && (e.key === 'ArrowDown' || e.key === 'ArrowUp')) {
        e.preventDefault()
        const step = 1
        const value = parseFloat(field.value)
        const newValue = e.key === 'ArrowDown' ? value - step : value + step
        helpers.setValue(newValue.toFixed(2))
      }
    },
    [field.value, helpers, props.type]
  )
  const [formData] = useFormData()

  const errors = formData.errors.fields[props.name] ?? []
  const isInvalid = (errors.length > 0 || meta.error != null) && meta.touched ? '!border-primaryRed' : ''
  const id = `label-${React.useId()}`

  if (isHorizontal === true) {
    return (
      <div className="mb-3 flex items-center">
        {props.label == null ? null : (
          <div className={`flex justify-end pr-4 ${fixedLabelWidth === true ? 'w-1/5 min-w-[130px]' : ''}`}>
            <label
              className={`flex font-bold text-primaryTextColor xxs:whitespace-normal ${
                rightAlign === true ? 'text-right' : ''
              }`}
              htmlFor={id}
            >
              {props.label}{' '}
              {required != null && (
                <span className="ml-1 text-primaryRed" data-testid={`${props.label}.required`}>
                  *
                </span>
              )}
            </label>
          </div>
        )}

        <div className="w-full">
          <div>
            <input
              {...field}
              {...props}
              id={id}
              className={`w-full cursor-text rounded-[4px] border border-borderColor bg-primaryWhite py-2.5 pl-3 text-bodyText text-primaryTextColor dark:bg-[#323232] ${
                props.className ?? ''
              } ${isInvalid}`}
              placeholder={props.placeholder}
              type={props.type !== 'number' ? props.type : 'text'}
              onChange={onChange}
              onKeyDown={handleKeyDown}
            />
          </div>
          {meta.touched && meta.error != null ? <div className="mt-2 text-primaryRed">{meta.error}</div> : null}
          {errors.map((error, i) => (
            <div key={i} className="mt-2 text-primaryRed">
              {error}
            </div>
          ))}
        </div>
      </div>
    )
  } else {
    return (
      <div className="mb-3 w-full">
        {props.label == null ? null : (
          <label className="mb-[10px] flex flex-nowrap items-center pl-0 font-bold text-primaryTextColor" htmlFor={id}>
            {props.label}{' '}
            {required != null && (
              <span className="pl-1 text-lightSecondaryWarning" data-testid={`${props.label}.required`}>
                *
              </span>
            )}{' '}
            {instructions}
          </label>
        )}
        <div>
          <div className="relative">
            <div className="relative rounded-md border border-borderColor bg-primaryWhite">
              <input
                {...field}
                {...props}
                ref={inputRef}
                id={id}
                placeholder={(searchField ?? false) ? '' : props.placeholder}
                className={classNames(
                  'focus:ring- block w-full cursor-text appearance-none rounded-md border-transparent p-2 text-[16px] font-normal text-primaryTextColor focus:border-transparent dark:bg-[#323232] dark:[color-scheme:dark]',
                  {
                    'cursor-not-allowed opacity-40 hover:!opacity-40': props.disabled,
                  },
                  props.className,
                  isInvalid,
                  {
                    'peer pl-[13px] pt-[13px]': searchField,
                  }
                )}
                style={{ height: 48 }}
                type={props.type !== 'number' ? props.type : 'text'}
                onChange={onChange}
                {...(props.type === 'number' ? { onKeyDown: handleKeyDown } : {})}
              />
              {sendOrDelete === true ? (
                <div className="absolute right-[12px] top-[12px] flex">
                  <span className="mt-[5px]">
                    <button
                      type="submit"
                      className={classNames(
                        'ml-2 duration-[200ms]',
                        field.value.length > 0 ? 'opacity-100' : 'cursor-default opacity-0'
                      )}
                      data-testid={`${props['data-testid']}-sendButton`}
                    >
                      <Checkmark />
                    </button>
                  </span>
                  <button
                    type="button"
                    onClick={() => void helpers.setValue('')}
                    className={classNames(
                      'ml-2 duration-[200ms]',
                      field.value.length > 0 ? 'opacity-100' : 'cursor-default opacity-0'
                    )}
                    data-testid={`${props['data-testid']}-clearButton`}
                  >
                    <Close2Icon />
                  </button>
                </div>
              ) : null}
            </div>
            {(searchField ?? false) && (
              <>
                <label
                  htmlFor={id}
                  className={classNames(
                    'text-ligh text-input-label absolute max-w-[85%] overflow-x-auto text-lightPrimaryIconText duration-[200ms] peer-focus:left-[13px] peer-focus:top-[10%] peer-focus:translate-y-[-10%] peer-focus:text-[11px] dark:text-captionColor',
                    {
                      'left-[13px] top-[5%] translate-y-[-10%] text-[11px]': field.value.length > 0,
                      'left-[13px] top-1/2 -translate-y-1/2': field.value.length <= 0,
                    }
                  )}
                >
                  <span className="whitespace-nowrap">
                    {props.placeholder}:{' '}
                    {required != null && (
                      <span className="pl-1 text-lightSecondaryWarning" data-testid={`${props.label}.required`}>
                        *
                      </span>
                    )}
                  </span>
                </label>
                <button
                  type="button"
                  onClick={() => void helpers.setValue('')}
                  className={classNames(
                    'absolute right-2 top-1/2 -translate-y-1/2 duration-[200ms]',
                    field.value.length > 0 ? 'opacity-100' : 'cursor-default opacity-0'
                  )}
                  data-testid={`${props['data-testid']}-clearButton`}
                >
                  <Close2Icon />
                </button>
              </>
            )}
          </div>
          {meta.touched && meta.error != null ? (
            <div className="mt-2 text-[13px] text-errorMessages">{meta.error}</div>
          ) : null}
          {errors.map((error, i) => (
            <div key={i} className="mt-2 text-[13px] text-errorMessages">
              {error}
            </div>
          ))}
        </div>
      </div>
    )
  }
}
